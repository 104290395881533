import { Observable } from "rxjs";
import { Delegate } from "../interfaces/delegate";
import { AppService } from "./base.service";
import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";

import { Calendar } from "@fullcalendar/core";
import { CalendarEvent, CalendarEvents } from "../interfaces/admin.interface";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AdminService implements Delegate {
  constructor(
    private appService: AppService,
    private requestService: RequestService,
    private http: HttpClient
  ) {}

   apiUrl : string = environment.restBaseUrl;


  getTodayData(date: string) {
    return this.appService.getAllMap("/api/admin/calendar/today");
  }
  getMapByDate(date: string) {
    return this.appService.getAllMap(
      `/api/admin/calendar/date/map?date=${date}`
    );
  }

  getRequests(
    type: string,
    startDate: string,
    endDate: string,
    status: string,
    idUser: string,
    orderBy: string
  ) {
    var filters: string = "?";
    console.log(startDate,endDate)
    if (type != null) {
      filters += `type=${type}&`;
    }
    if (startDate != null && startDate.length > 0) {
      filters += `startDate=${startDate}&`;
    }
    if (endDate != null && endDate.length > 0) {
      filters += `endDate=${endDate}&`;
    }
    if (status != null) {
      filters += `status=${status}&`;
    }
    if (idUser != null) {
      filters += `uuidUser=${idUser}&`;
    }
    if(orderBy != null){
      filters += `${orderBy}&`;
    }
    return this.appService.getAll(`/api/admin/requests${filters}`);
  }

  getSpecificUsers(id?: string) {
    return this.appService.getList(`/api/admin/user/${id}`, null);
  }

  getUsersCalendarData(date: string, id: string): Observable<CalendarEvents> {
    return this.appService.getAllMap(
      `/api/admin/calendar/user/map/${id}?date=${date}`
    );
  }

  getVouchers(calendarday: string, uuidUser: string) {
    return this.appService.getAll(
      `/api/admin/calendar/vouchers?calendarDay=${calendarday}&uuidUser=${uuidUser}`
    );
  }

  rejectrequest(idRequest: number) {
    return this.requestService.rejectRequest(
      idRequest,
      "/api/admin/request/reject/"
    );
  }

  editCalendar(calendarId: number, body: any) {
    return this.appService.editElement(
      `/api/admin/calendar/${calendarId}`,
      body
    );
  }

  postCalendar(body: CalendarEvent, idUser : string) {
    return this.appService.newElement('/api/admin/calendar/'+idUser, body)
  }
  getCalendarDay(date: string, idUser?: string) {
      return this.appService.getElement(`/api/admin/calendar/user/list/${idUser}?date=${date}`)
  }
  getMenu(){
    return this.appService.getElement("/menu?role=ADMIN")
  }

  postRequest() {
    return null
  }
}
