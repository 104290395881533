import { Observable } from "rxjs";
import { Delegate } from "../interfaces/delegate";
import { AppService } from "./base.service";
import { Injectable } from "@angular/core";
import { RequestService } from "./request.service";
import { CalendarEvent, CalendarEvents } from "../interfaces/admin.interface";

@Injectable({
  providedIn: "root",
})
export class UserService implements Delegate {
  constructor(
    private appService: AppService,
    private requestService: RequestService
  ) {}
  getTodayData(date: string): Observable<any> {
    return this.appService.getAllMap(`/api/user/calendar/map?date=${date}`);
  }

  getMapByDate(date: string) {
    return this.appService.getAllMap(`/api/user/calendar/map?date=${date}`);
  }

  getRequests(
    type: string,
    startDate: string,
    endDate: string,
    status: string,
    idUser: string, 
    orderBy: string
  ) {
    var filters: string = "?";

    if (type != null) {
      filters += `type=${type}&`;
    }
    if (startDate != null && startDate.length > 0) {
      filters += `startDate=${startDate}&`;
    }
    if (endDate != null && endDate.length > 0) {
      filters += `endDate=${endDate}&`;
    }
    if (status != null) {
      filters += `status=${status}&`;
    }
    if (idUser != null) {
      filters += `uuidUser=${idUser}&`;
    }
    if(orderBy != null){
      filters += `${orderBy}&`;
    }
    console.log(filters)
    return this.appService.getAll(`/api/user/request${filters}`);
  }

  getVouchers(calendarday: string, uuidUser: string) {
    console.log("unauthorized");
  }
  getSpecificUsers() {
    this.appService.getElement("/getuser");
  }

  getUsersCalendarData(date: string, id?: string): Observable<CalendarEvents> {
    return this.appService.getAllMap(`/api/user/calendar/map?date=${date}`);
  }
  getRequest(): Observable<any> {
    return this.appService.getAll(
      `/api/user/request?type=FERIE&status=PENDING`
    );
  }
  getVacations(startDate: string, endDate: string): Observable<any> {
    console.log(
      `/api/user/request?type=FERIE&status=APPROVED&startDate=${startDate}&endDate=${endDate}`
    );
    return this.appService.getAll(
      `/api/user/request?type=FERIE&status=APPROVED&startDate=${startDate}&endDate=${endDate}`
    );
  }

  rejectrequest(idRequest: number) {
    return this.requestService.rejectRequest(
      idRequest,
      "/api/user/request/reject/"
    );
  }

  editCalendar(calendarId: number, body: any) {
    return this.appService.editElement(
      `/api/user/calendar/${calendarId}`,
      body
    );
  }

  postCalendar(body: CalendarEvent, idUser?: string) {
    return this.appService.newElement(`/api/user/calendar`, body)
  }

  getCalendarDay(date: string, idUser?: string) {
   return this.appService.getElement(`/api/user/date/${date}`)
  }
  getMenu(){
    return this.appService.getElement("/menu?role=USER")
  }

  postRequest(body : any) {
    return this.appService.newElement("/api/user/request", body)
  }
}
