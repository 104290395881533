<div class="rounded-4 rounded border border-black bg-white p-4">
  <h2 class="border-bottom border-black pt-3 pb-3">Richieste</h2>

  <!-- FIRST ROW -->
  <div class="col">
    <div class="col-12 d-flex  justify-content-between">
      
      <div class="col-3 d-flex mr-1 align-items-end ">
        <button type="button" class="btn btn-secondary col-12" (click)="filterByAll()"
          [ngClass]="{ 'border border-info text-dark': allRequests }">
          Tutte le Richieste
        </button>
      </div>

      <div class="col-3  mr-1 d-flex align-items-end">
        <button type="button" class="btn btn-secondary col-12" (click)="filterByVacation()"
          [ngClass]="{ 'border border-info text-dark': vacationRequests }">
          Richieste di Ferie
        </button>
      </div>

      <div class="col-3 mr-1 d-flex align-items-end">
        <button type="button" class="btn btn-secondary col-12" (click)="filterByClosing()"
          [ngClass]="{ 'border border-info text-dark': closingRequests }">
          Richieste di Chiusura
        </button>
      </div>

      <div class="col-3 px-1" [ngClass]="{ ' border-bottom border-danger': !isDateValid }">
        <label htmlFor="monthPicker">Seleziona date:</label>

        <button (click)="showMonthPicker()" *ngIf="!isMonthPickerVisible" class="btn w-100 btn-outline-secondary">
          <i class="fa-regular fa-calendar"></i>
          {{ startDate && endDate ? startDate + " - " + endDate : "CALENDARIO" }}
        </button>

        <month-picker (monthRangeSelected)="handleMonthRangeSelected($event)" *ngIf="isMonthPickerVisible"
          (displayEvent)="changeMonthPickerVisibility($event)"></month-picker>
    </div>
  </div>

  <!-- SECOND ROW-->
  <div class="col-12 d-flex justify-content-between mt-3 pr-0">
    <div class="col-3">  
    <label for="userSelect" class="form-label">{{role==="ADMIN" ? 'Dipendente: ' : 'Chiusura Mensile: '}}</label>
      <s1-input-select id="userSelect" [itemsList]="usersList" (onSelect)="filterByUser($event)" *ngIf="role==='ADMIN'"></s1-input-select>
    </div>
      <button class="btn btn-secondary" 
        *ngIf="role!=='ADMIN'"
        (click)="sendMonthlyRequest()">
        <i class="fa-regular fa-paper-plane fa-sm"></i>
         INVIA RICHIESTA</button>
    <div class="col-3">
      <label for="statusSelect" class="form-label">Stato:</label>
      <s1-input-select id="statusSelect" [itemsList]="statusList" (onSelect)="filterByStatus($event)"></s1-input-select>
    </div>

      <div class="col-3">
        <label for="">Ordina per:</label>
        <s1-input-select id="orderSelect" [itemsList]="orderList" (onSelect)="orderByCode($event)"></s1-input-select>
      </div>
      <div class="col-3 d-flex justify-content-center align-items-end">
        <button class="btn btn-outline-info col-12 h-50 my-2" (click)="filterList()" [disabled]="!isDateValid">
          CERCA
        </button>
      </div>
    </div>
  </div> 

  <div class="mt-5">
  <s1-table class="w-10" [paginationVisible]="true" #table [onlinePagination]="false">
    <thead header>
      <tr class="row header p-0">
        <th class="col-1 d-flex justify-content-center">Tipologia</th>
        <th class="col-5 text-start">Dipendente</th>
        <th class="{{role==='ADMIN' ? 'col-2' : 'col-2'}}">Stato</th>
        <th class="{{role==='ADMIN' ? 'col-1' : 'col-1'}}">Data inizio</th>
        <th class="{{role==='ADMIN' ? 'col-1' : 'col-1'}} text-center">Data fine</th>
        <th class="col-1 d-flex justify-content-center">Visualizza</th>
        <th class="col-1 d-flex justify-content-center" *ngIf="role==='ADMIN'">Operazioni</th>
      </tr>
    </thead>
    <tbody body>
      <!--<app-admin-request *ngFor="let request of requestList" [request]="request" (modalEvent) = "handleModalEvent()"></app-admin-request>-->
      <app-admin-request *ngFor="let request of table.config.rows" [request]="request"
        (modalEvent)="handleModalEvent()"></app-admin-request>
    </tbody>
  </s1-table>
</div>
</div>
